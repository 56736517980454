import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useRealmApp } from "../contexts/RealmApp";
import { PATIENT } from "../constants/pagePaths";
import { getUserPatientType, getUserStatus } from "../utils/utils";
import { UserStatusID } from "../constants/common";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";

// ログイン必須画面(ユーザ情報未登録ユーザ)
const RequiredLoginUnregisteredElement: React.FC<{ element: JSX.Element }> = ({
  element,
}) => {
  const realmAppContext = useRealmApp();
  const isLogin = Boolean(realmAppContext.currentUser);

  // 初期表示時データをリフレッシュ
  const [isRefresh, setIsRefresh] = useState(false);
  useEffect(() => {
    const refreshData = async () => {
      addLoadCount();
      try {
        await realmAppContext.currentUser?.refreshCustomData();
        setIsRefresh(true);
      } finally {
        decrementLoadCount();
      }
    };
    if (!isRefresh) void refreshData();
  }, [isRefresh, realmAppContext.currentUser]);

  const status = getUserStatus(realmAppContext.currentUser);
  const patientType = getUserPatientType(realmAppContext.currentUser);
  const isActive = status === UserStatusID.ACTIVE;
  // リフレッシュ中は非表示
  if (!isRefresh) return <></>;
  // 未登録の場合、TOP画面に遷移(ログイン状態でactieステータスの場合、トップ画面へ)
  // isActive=1の場合、決済済みなので、申込画面(決済画面が含まれている)
  if (isLogin && isActive && patientType)
    return <Navigate to={PATIENT.USB001} />;

  return isLogin ? element : <Navigate to={PATIENT.USA001} />;
};

export default RequiredLoginUnregisteredElement;
